import React , {useState} from 'react'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector ,useDispatch } from 'react-redux';
import { addProductItem } from '../../Redux/action/Product'
import { clearProductData } from '../../Redux/action/App'
import IsLoadingHOC from '../IsLoadingHOC'
import IsloggedinHOC from '../IsLoggedinHOC';
import downPng from '../../assets/img/down.png'
import View from "../View";

const FooterProduct = (props) => {
    const { setLoading } = props;
    const dispatch = useDispatch();
    const history = useHistory()
    const[viewShow ,setViewShow] =useState(false);
    const {user} = useSelector(state => state.auth)
    const {footerProductData} = useSelector(state =>state.app)

    console.log("footerProductData" ,footerProductData)
   
   const counters =(values) =>{
       if(values && values.length > 0){
        const total = values.reduce((previousValue, currentValue) => previousValue + currentValue.product_price , 0)
            return total
       }
    }

    const viewHandler = () =>{
        setViewShow(!viewShow)
    }

    const submitHandler = () => {
        const payload = {
            product_details : [...footerProductData]
        }
        setLoading(true)
        dispatch(addProductItem(payload))
        .then(
            response => {
                const  ticket_id = response.data.ticket_id
                if(ticket_id){
                    setLoading(false)
                    toast.success("Product add successfully")
                    dispatch(clearProductData())
                    history.push(`/seller/ticket/${ticket_id}`)
                }
            },
            (error) => {
                setLoading(false)
                toast.error("Some occured error")
                console.log("error" ,  error);
            }
        )
        .catch(
            error => console.log(error)
        )
    }

    return (
        <div className="submit--product--section">
            <div className="submit--product">
                <div  style={{backgroundColor : "#D3D3D3"}} className="product--item--header">
                    <div className="product--item--inner">
                        <div className="view--product">
                            <div className="view-product--wrapper" onClick={viewHandler}>
                                <h3>View</h3>
                                <img src= {downPng} alt="" />
                            </div>
                        </div>
                        <div className="added--product">
                            <div className="added-product--wrapper">
                                <h3>Added Items</h3>
                                <h5>{ footerProductData && footerProductData.length}</h5>
                            </div>
                        </div>
                        <div className="value--product">
                            <div className="value-product--wrapper">
                                <h3>Value</h3>
                                <h5><span>$</span>{counters(footerProductData)}</h5>
                            </div>
                        </div>
                        <div className="submit--product add_product ">
                            <div className=" submit-product--wrapper">
                            <button className='btn'  type="button" onClick={submitHandler} >Submit Items</button>
                            </div>
                        </div>
                    </div>
                </div>
                <View viewShow ={viewShow} />
            </div>
        </div>
    )
}

export default  IsLoadingHOC(IsloggedinHOC(FooterProduct));