import { combineReducers } from 'redux';

import auth from './Auth';
import app from './App';
import product from './Product';

const appReducer = combineReducers({
    auth,
    app,
    product
});

const initialState = appReducer({}, {}, {})

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = initialState
    }

    return appReducer(state, action)
}

export default rootReducer;