import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from "../../Redux/action/App"
// import SubEditProduct from './SubEditProduct';
import IsLoadingHOC from '../IsLoadingHOC';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify"
import { getProductList, addManuallyProductAction } from '../../Redux/action/Product'
import { getModuleData } from '../../Redux/action/App'



const AddManuallyProduct = (props) => {
    const { manuallyProductHandler, setLoading } = props
    const dispatch = useDispatch();


    const handleModal = (item) => {
        let modelObj = {
            isModalOpen: true,
            product_details: item,
            show: true
        }
        dispatch(getModuleData(modelObj))
    }

    const formik = useFormik({
        initialValues: {
            product_name: '',
            category: '',
            brand: '',
            model: '',
            color: '',
            sku: '',
            description: '',
            product_image: null
        },

        // validationSchema: Yup.object({
        //     name: Yup.string()
        //         .required("Username is required")
        //         .min(5, 'Username must be at least 5 characters'),
        //     mobile: Yup.string()
        //         .required("Mobile number is required"),
        //     address1: Yup.string()
        //         .required("Address Line 1 is required"),
        //     address2: Yup.string()
        //         .required("Address Line 2 is required"),
        // }),

        onSubmit: async values => {
            console.log('on Submit hit ------', values);
            const fieldValues = values
            const { product_name, category, brand, model, color, sku, description, product_image } = fieldValues

            // const data = new FormData()
            // data.append('product_name',  product_name);
            // data.append('category',  category);
            // data.append('brand', brand);
            // data.append('model', model);
            // data.append('color', color);
            // data.append('sku', sku);
            // data.append('description', description);
            // data.append('product_image', product_image);

            await dispatch(addManuallyProductAction(fieldValues))
                .then(
                    response => {
                        toast.success(response.message)
                        handleModal(response.data)
                    },
                    error => {
                        toast.error(error.response.data.message);
                    }
                )
                .catch(
                    error => console.log(error)
                )
        },
    });



    return (
        <>
            <div id="myModal" className="modal fade show " style={{ display: "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn" onClick={manuallyProductHandler} data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body" id="product_id">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h4 className="product_name">Add a Product</h4>
                                        <ul className="list-inline">
                                            <li className="list-inline-item">We didn’t find the product you are looking for in our system, please add it and we will review it.</li>
                                        </ul>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <form onSubmit={formik.handleSubmit} >
                                <div className="sc-LzLth eCXFEc"><div className="Select___StyledDiv-wqytkt-0 gRMwLe">
                                    <label className="Select___StyledLabel-wqytkt-1 eKOFse sg-fndtn" id="2">
                                        <div className="LabelText___StyledDiv-eihk0o-0 fxjgMZ">Category</div>
                                    </label><div aria-hidden="true" className="Select___StyledDiv2-wqytkt-2 kmnMXy">▾
                                    </div>
                                    <select
                                        name="category"
                                        onChange={formik.handleChange}
                                        value={formik.values.category}
                                        onBlur={formik.handleBlur}
                                        className="Select___StyledSelect-wqytkt-3 cjihpU sg-fndtn" required="" aria-labelledby="2">
                                        <option value="Adidas Y-3">Select Category</option>
                                        <option value="Adidas Y-3">Adidas Y-3</option>
                                        <option value="Kith">Kith</option>
                                        <option value="Kobe - Nike Basketball">Kobe - Nike Basketball</option>
                                    </select>
                                </div>


                                    <div className="Select___StyledDiv-wqytkt-0 gRMwLe">
                                        <label className="Select___StyledLabel-wqytkt-1 eKOFse sg-fndtn" id="3">
                                            <div className="LabelText___StyledDiv-eihk0o-0 fxjgMZ">Brand</div>
                                        </label><div aria-hidden="true" className="Select___StyledDiv2-wqytkt-2 kmnMXy">▾</div>
                                        <select
                                            name="brand"
                                            onChange={formik.handleChange}
                                            value={formik.values.brand}
                                            onBlur={formik.handleBlur}
                                            className="Select___StyledSelect-wqytkt-3 cjihpU sg-fndtn" required="" aria-labelledby="3">
                                            <option value="DELANTIC">DELANTIC</option>
                                            <option value="DFNS">DFNS</option>
                                            <option value="DIADORA">DIADORA</option>
                                            <option value="DIAMOND SUPPLY">DIAMOND SUPPLY</option>
                                        </select>
                                    </div>

                                    <div>
                                        <div className="BaseInput___StyledDiv-s2zwdy-0 eZlxjm">
                                            <label className="BaseInput___StyledLabel-s2zwdy-1 bQygTD sg-fndtn" aria-describedby="4-description" id="4">
                                                <div className="BodyText___StyledDiv2-sc-8v9xcx-1 dVXLei">Model</div>
                                            </label>
                                            <input
                                                name="model"
                                                onChange={formik.handleChange}
                                                value={formik.values.model}
                                                onBlur={formik.handleBlur}
                                                className="BaseInput___StyledInput-s2zwdy-2 bLklSs sg-fndtn" type="text" size="1" aria-labelledby="4" />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="BaseInput___StyledDiv-s2zwdy-0 eZlxjm">
                                            <label className="BaseInput___StyledLabel-s2zwdy-1 bQygTD sg-fndtn" aria-describedby="5-description" id="5">
                                                <div className="BodyText___StyledDiv2-sc-8v9xcx-1 dVXLei">Color</div>
                                            </label>
                                            <input
                                                name="color"
                                                onChange={formik.handleChange}
                                                value={formik.values.color}
                                                onBlur={formik.handleBlur}
                                                className="BaseInput___StyledInput-s2zwdy-2 bLklSs sg-fndtn" type="text" required="" size="1" aria-labelledby="5" /></div>
                                    </div>
                                    <div>
                                        <div className="BaseInput___StyledDiv-s2zwdy-0 eZlxjm">
                                            <label className="BaseInput___StyledLabel-s2zwdy-1 bQygTD sg-fndtn" aria-describedby="6-description" id="6">
                                                <div className="BodyText___StyledDiv2-sc-8v9xcx-1 dVXLei">SKU</div>
                                            </label>
                                            <input
                                                name="sku"
                                                onChange={formik.handleChange}
                                                value={formik.values.sku}
                                                onBlur={formik.handleBlur}
                                                className="BaseInput___StyledInput-s2zwdy-2 bLklSs sg-fndtn" type="text" required="" size="1" aria-labelledby="6" />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="BaseInput___StyledDiv-s2zwdy-0 eZlxjm"><label className="BaseInput___StyledLabel-s2zwdy-1 bQygTD sg-fndtn" aria-describedby="7-description" id="7">
                                            <div className="BodyText___StyledDiv2-sc-8v9xcx-1 dVXLei">Nickname (Optional)</div>
                                        </label>
                                            <input
                                                name="product_name"
                                                onChange={formik.handleChange}
                                                value={formik.values.product_name}
                                                onBlur={formik.handleBlur}
                                                className="BaseInput___StyledInput-s2zwdy-2 bLklSs sg-fndtn" type="text" size="1" aria-labelledby="7" />
                                        </div>
                                    </div>
                                </div>

                                <div className="TextArea___StyledDiv-sc-1n3brdb-0 eEyGwl">
                                    <label className="TextArea___StyledLabel-sc-1n3brdb-1 hcrCte sg-fndtn" aria-describedby="29-description" id="29">
                                        <div className="BodyText___StyledDiv2-sc-8v9xcx-1 dVXLei">Description</div>
                                    </label>
                                    <textarea
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                        onBlur={formik.handleBlur}
                                        className="TextArea___StyledTextarea-sc-1n3brdb-2 gExzUt sg-fndtn" required="" rows="4" aria-labelledby="29">
                                    </textarea>
                                </div>

                                <div className="TextArea___StyledDiv-sc-1n3brdb-0 eEyGwl">
                                    <label className="TextArea___StyledLabel-sc-1n3brdb-1 hcrCte sg-fndtn" aria-describedby="29-description" id="29">
                                        <div className="BodyText___StyledDiv2-sc-8v9xcx-1 dVXLei">Image</div>
                                    </label>
                                    <input
                                        name="product_image"
                                        type="file"
                                        onChange={(event) => {
                                            const productImage = event.target.files[0]
                                            console.log("productImage-----", productImage)
                                            formik.setFieldValue('product_image', productImage)
                                        }}

                                        onBlur={formik.handleBlur}
                                        className="TextArea___StyledTextarea-sc-1n3brdb-2 gExzUt sg-fndtn" required="" rows="4" aria-labelledby="29">
                                    </input>
                                </div>
                                <div className="add_product">
                                    <button type='submit' className="btn btn-lg mb-2 " id="add_products" >
                                        Continue</button>
                                </div>
                            </form>
                            {/* <SubEditProduct getSellerTicketData = {getSellerTicketData} /> */}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={manuallyProductHandler}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default IsLoadingHOC(AddManuallyProduct);