import React from "react";
import { useDispatch } from 'react-redux'
import IsLoadingHOC from '../IsLoadingHOC';
import { toast } from "react-toastify"
import {changePasswordAction} from '../../Redux/action/Auth'
import { useFormik } from 'formik';
import * as Yup from 'yup';



const ChangePassword = (props) => {
    const { setLoading } = props
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            new_password: '',
            confirm_password : ''
        },

        validationSchema: Yup.object({
                new_password: Yup.string()
                .required("New Password is required")
                .min(8, 'New password must be at least 8 characters'),
                confirm_password: Yup.string()
                .required("Confirm password is required")
                .oneOf([Yup.ref('new_password'), null], 'Confirm Password does not match')
        }),
        //   validation schema ends
        onSubmit: async values => {
            console.log('on Submit hit ------', values);
            const fieldValues = values
            setLoading(true)
            await dispatch(changePasswordAction(fieldValues))
              .then(
                response => {
                  setLoading(false)
                  toast.success(response.message)
                },
                error => {
                  setLoading(false)
                  toast.error(error.response.data.message);
                }
              )
              .catch(
                error => console.log(error)
              )
        },
    });
    return (
        <div class="col-md-4">
            <div class="p-3 py-5">
                <div class="d-flex justify-content-between align-items-center experience">
                    <h4 className="text-right">Change password</h4>
                    {/* <span>Change Password</span> 
                            <span class="border px-3 p-1 add-experience">
                            <i class="fa fa-plus"></i>&nbsp;Experience</span> */}
                </div><br />
                <form onSubmit={formik.handleSubmit} >
                <div class="col-md-12">
                    <label class="labels">New Password</label>
                    <input
                        type="password"
                        name="new_password"
                        value = {formik.values.new_password}
                        onChange ={formik.handleChange}
                        class="form-control"
                        placeholder="Enter new Password"
                        />
                     {formik.touched.new_password && formik.errors.new_password ? (<span className=" text-danger " >{formik.errors.new_password}</span>) : null}
                    </div><br />
                    <div class="col-md-12">
                    <label class="labels">Confirm password</label>
                    <input
                        type="password"
                        name="confirm_password"
                        value = {formik.values.confirm_password}
                        onChange ={formik.handleChange}
                        class="form-control"
                        placeholder="Enter confirm Password"
                    />
                    {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className=" text-danger " >{formik.errors.confirm_password}</span>) : null}
                </div>
                <div className="mt-5 text-center">
                    <button className="btn btn-primary profile-button" type="submit">Change Password</button>
                </div>
                </form>
            </div>
        </div> 
    )
}

export default IsLoadingHOC(ChangePassword)