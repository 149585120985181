import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal} from "../../Redux/action/App"
import SubEditProduct from './SubEditProduct';
import IsloggedinHOC from '../../Components/IsLoggedinHOC';


const EditModal = (props) => {
    const {getSellerTicketData} = props
    const { current} = useSelector(state => state.app)
    const { show, product_details } = current ? current : {}
    const dispatch = useDispatch();

    const handlerClose = () => {
        dispatch(closeModal({ isModalOpen: false, }))
    }

    const capitalizeFirstLetter = (string) => {
        if(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
      }


    return (
        <>
            <div id="myModal" className="modal fade show " style={{ display: show === true ? "block" : "none" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn" onClick={handlerClose} data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body" id="product_id">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-3 product_image">
                                        {product_details.image && product_details.image.src ? <img src={product_details.image.src} className="img-fluid"/>
                                        :
                                        <img src={"https://truth-events.com/wp-content/uploads/2019/09/dummy.jpg"} className="img-fluid" />
                                        }
                                    </div>
                                    <div className="col-md-9">
                                        <h4 className="product_name">{capitalizeFirstLetter(product_details[0]?.product_name)}</h4>
                                        <ul className="list-inline">
                                            <li className="list-inline-item">SKU {product_details[0]?.product_sku}</li>
                                            {/* <li className="list-inline-item">{product_details.options[0].values}</li> */}
                                        </ul>
                                        <div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item">MENS</li>
                                                <li className="list-inline-item">2020</li>
                                                <li className="list-inline-item">WHITE ON WHITE</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <hr /> 
                            </div>
                            <SubEditProduct getSellerTicketData = {getSellerTicketData} />
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={handlerClose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default IsloggedinHOC(EditModal);