
let initialState = {
    current: "",
    subProductData: [],
    footerProductData: [],
    productViewData: [],
    initialSubData: [],
    notifications: [],
    approvedData: [],
    pendingData: []
}


export default function App(state = initialState, action) {
    switch (action.type) {
        case "MODAL_DATA_UPDATE":
            return { ...state, current: { ...state.current, ...action.payload } }
        case "CLOSE_MODAL":
            return { ...state, current: action.payload }
        case "ADD_PRODUCT_DATA":
            return { ...state, subProductData: action.payload }
        case "SET_INITIALSUB_DATA":
            return { ...state, initialSubData: action.payload }
        case "ADD_FOOTER_PRODUCT_DATA":
            return { ...state, footerProductData: action.payload }
        case "ADD_PRODUCT_VIEW_DATA":
            return { ...state, productViewData: [...state.productViewData, action.payload] }
        case "CLEAR_PRODUCT_DATA":
            return {
                ...state,
                subProductData: [],
                footerProductData: [],
                productViewData: [],
                initialSubData: [],
                approvedData: [],
                pendingData: []
            }
        case "SET_NOTIFICATIONS":
            return { ...state, notifications: action.payload }
        case "ADD_APPROVED_DATA":
            return { ...state, approvedData: action.payload}
        case "ADD_PENDING_DATA":
            return { ...state, pendingData: action.payload }
        default:
            return state
    }
}

