import React, { useState, useEffect } from "react";
import questionImg from '../../assets/img/qustion.png'
import approvedImg from '../../assets/img/approved.png'
import pendingImg from '../../assets/img/under-reviw.png'
import { getModuleData } from '../../Redux/action/App'
import { useDispatch, useSelector } from 'react-redux'
import IsloggedinHOC from "../IsLoggedinHOC";



const View = (props) => {
    const { viewShow } = props
    const { approvedData, pendingData } = useSelector(state => state.app)
    const {product_list} = useSelector(state => state.product)
    const [approval_data, setApprovalData] = useState([])
    const [pending_data, setPendingData] = useState([])
    const dispatch = useDispatch();


    useEffect(() => {
        const key = 'product_id';
        const uniqueApproval = [...new Map(approvedData.map(item => [item[key], item])).values()];
        const uniquePending = [...new Map(pendingData.map(item => [item[key], item])).values()];
        setApprovalData(uniqueApproval)
        setPendingData(uniquePending)
    }, [approvedData, pendingData])


    const handleModal = (productId) => {
        const result = product_list.find(({ id }) =>  id === productId);
       if(result){
        let modelObj = {
            isModalOpen: true,
            product_details: result,
            show: true
        }
        dispatch(getModuleData(modelObj))
    }
    }

    return (
        <div style={{
            display: viewShow ? "block" : "none",
            background: 'rgb(239, 239, 239)',
            minHeight: "100vh",
          
        }}
        className="product--item--data">
            {approval_data && approval_data.length > 0 &&
            <div className="product--item--data-item">
                <div className="pre--aproved">
                    <div className="pre--aproved--text">
                        <img src= {approvedImg} alt="" />
                        <h4>Pre-approved</h4>
                    </div>
                </div>
                <div className="pre-approved-data">
                    <div className="pre-ap-data">
                        <span>The following items have been pre-approved and are ready to be inspected.</span>
                        <img src={questionImg}alt="" />
                    </div>
                </div>
                <div className="products--items">
                    <div className="products--item--inner">
                        {approval_data && approval_data.length > 0 && approval_data.map((item, index) =>
                            <div key={index} onClick ={() => handleModal(item.product_id)} className="product--item">
                                <span className="badge">{item.product_size && item.product_size.length ? item.product_size.length : ''}</span>
                                <img src={item.product_image ? item.product_image  : "https://truth-events.com/wp-content/uploads/2019/09/dummy.jpg" } alt="" />
                                <h3>{item.product_name}</h3>
                                <h5><span>
                                    {item.product_id}</span> |
                                    {item.product_size && item.product_size.length > 0 && item.product_size.map((size, i) =>
                                        i === item.product_size.length - 1 ? `${size}` : `${size} ,`
                                    )}
                                </h5>
                            </div>
                        )}
                    </div>
                </div>
            </div>}

            {pending_data && pending_data.length > 0 &&
            <div className="product--item--data-item">
                <div className="pre--aproved">
                    <div className="pre--aproved--text">
                        <img src= {pendingImg} alt="" />
                        <h4>UNDER REVIEW</h4>
                    </div>
                </div>
                <div className="pre-approved-data">
                    <div className="pre-ap-data">
                        <span>The following items have not been pre-approved. Edit items to qualify for pre-approval.</span>
                        <img src={questionImg}alt="" />
                    </div>
                </div>
                <div className="products--items">
                    <div className="products--item--inner">
                        {pending_data && pending_data.length > 0 && pending_data.map((item, index) =>
                            <div key={index} onClick ={() => handleModal(item.product_id)} className="product--item">
                                <span className="badge">{item.product_size && item.product_size.length ? item.product_size.length : ''}</span>
                                <img src={item.product_image ? item.product_image  : "https://truth-events.com/wp-content/uploads/2019/09/dummy.jpg" } alt="" />
                                <h3>{item.product_name}</h3>
                                <h5><span>
                                    {item.product_id}</span> |
                                    {item.product_size && item.product_size.length > 0 && item.product_size.map((size, i) =>
                                        i === item.product_size.length - 1 ? `${size}` : `${size} ,`
                                    )}
                                </h5>
                            </div>
                        )}

                        {/* 
                    <div className="product--item">
                        <span className="badge">4</span>
                        <img src="img/shose.png" alt=""/>
                        <h3>Air Jordan 1</h3>
                        <h5><span>555088 063</span>  | 3.5</h5>
                    </div> */}

                    </div>
                </div>
                {/* <div className="no--product--yet">
                <span>No products yet</span>
            </div> */}
            </div>}
        </div>
    )
}
export default IsloggedinHOC(View);