import React , {useState } from 'react';
import { Button } from 'bootstrap';
import { Form } from 'formik';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { LogOut } from '../../../Redux/action/Auth';
import { FaBell } from "@react-icons/all-files/fa/FaBell";
import Notifications from '../../../Components/Notifications'
import PopupModel from '../../../Components/Notifications/popupModel';


const Header = () => {
    const dispatch = useDispatch();
    const name = useSelector(state => state.auth.user.name);
    const [show, setShow] = useState(false);

    const hideModel = () => setShow(false);
   


    return (
        <div>
        
        <Navbar expand="lg" bg="dark" variant="dark">
            <Container fluid>
                <Navbar.Brand href="/">Stay Fresh</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/seller/tickets">Ticket</Nav.Link>
                        <Nav.Link href="/seller/offers">Counter Offer</Nav.Link>

                    </Nav>
                    <Nav>
                        <Nav.Link onClick={() => setShow(true)} href="#"> <FaBell /><span  ><Notifications /></span></Nav.Link>

                    </Nav>
                    <Nav>
                        <NavDropdown title={name} id="navbarScrollingDropdown">
                            <NavDropdown.Item href="/user/profile">Profile</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => dispatch(LogOut())}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
           
        </Navbar>
         {show && <PopupModel hideModel = {hideModel} show = {show} />}
       
        </div>

       

       
    )
}

export default Header