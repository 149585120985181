import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, addInitialSubAction } from "../../Redux/action/App"
import SubProductItem from '../subProduct';
import IsloggedinHOC from '../IsLoggedinHOC';


const DummyModal = (props) => {
    const [item, setItem] = useState([])
    const [itemImage , setItemImage] =useState('')
    const [sku , setSku] =useState('')
    const [subProduct, setSubProduct] = useState([])
    const [selectedSize, setSelectedSize] = useState([])
    const { current, initialSubData, footerProductData } = useSelector(state => state.app)
    const { show, product_details } = current ? current : {}
    const dispatch = useDispatch();


    useEffect(() => {
        const sizeData = []
        const selected_size = []
        const dummySize = ["3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12.5", "13", "13.5", "14", "14.5", "15", "16", "17", "18"]
        setItem(dummySize)
        const filterData = footerProductData.filter(item => item.product_id === product_details.id)
        setSubProduct(filterData)
        if (filterData) {
            filterData.map(item => {
                selected_size.push(item.product_size)
            })
            setSelectedSize(selected_size)
        }
    }, [])

    useEffect(() => {
        if(product_details.image && product_details.image.src){
            const product_image  = product_details.image.src
            setItemImage(product_image)
        }
        if(product_details.variants[0] && product_details.variants[0].sku){
            const product_sku = product_details.variants[0].sku
            setSku(product_sku)
        }
    },[])

    const handlerSetInitValue = (event) => {
        let subProductSize = [...initialSubData]
        const result = subProductSize.find(({ product_size, product_id }) => product_size === event && product_id === product_details.id);
        if (result) {
            var idx = subProductSize.indexOf(result)
            if (idx !== -1) {
                subProductSize.splice(idx, 1);
                setSubProduct(subProductSize)
                dispatch(addInitialSubAction(subProductSize))
            }
        }
        else {
            let data = product_details.variants
            for (let i = 0; i < data.length; i++) {
                if (data[i].option1 === event || data[i].option2 === event) {
                    let dummyObj = {
                        product_size: event,
                        price: parseInt(data[i].price),
                        product_id: data[i].product_id,
                    }
                    subProductSize.push(dummyObj)
                    setSubProduct(subProductSize)
                    dispatch(addInitialSubAction(subProductSize))
                    break;
                }
            }
        }
    }

    const handlerClose = () => {
        dispatch(closeModal({ isModalOpen: false, }))
    }

    const subProductHandler = (event) => {
        let subProductSize = [...subProduct]
        const result = subProductSize.find(({ product_size, product_id }) => product_size === event && product_id === product_details.id);
        if (result) {
            var idx = subProductSize.indexOf(result)
            if (idx !== -1) {
                subProductSize.splice(idx, 1);
                setSubProduct(subProductSize)
            }
        }
        else {
            let data = product_details.variants
            for (let i = 0; i < data.length; i++) {
                if (data[i].option1 === event || data[i].option2 === event) {
                    let dummyObj = {
                        p_id: `${data[i].product_id}-${event}`,
                        product_size: event,
                        product_price: parseInt(data[i].price),
                        product_id: data[i].product_id,
                        product_image : itemImage ? itemImage : '',
                        product_name: product_details.title,
                        product_quantity: '1',
                        product_type: "Sample",
                        ticket_status: "Approved",
                        product_sku: sku ? sku : ''
                    }
                    subProductSize.push(dummyObj)
                    setSubProduct(subProductSize)
                    break;
                }
                else {
                    let dummyObj = {
                        p_id: `${data[i].product_id}-${event}`,
                        product_size: event,
                        product_price: 0,
                        product_id: data[i].product_id,
                        product_image : itemImage ? itemImage : '',
                        product_name: product_details.title,
                        product_quantity: '1',
                        product_type: "Sample",
                        ticket_status: "Approved",
                        product_sku: sku ? sku : ''
                    }
                    subProductSize.push(dummyObj)
                    setSubProduct(subProductSize)
                    break;
                }
            }
        }
    }

    const selectedSizeHandle = (size) => {
        const selected_size = [...selectedSize]
        const found = selected_size.find(element => element === size);
        if (found) {
            var idx = selected_size.indexOf(found)
            if (idx !== -1) {
                selected_size.splice(idx, 1);
                setSelectedSize(selected_size)
            }
        }
        else {
            selected_size.push(size)
            setSelectedSize(selected_size)
        }
    }

    const productSizeHandle = (size) => {
        handlerSetInitValue(size);
        subProductHandler(size);
        selectedSizeHandle(size);
    }

    const capitalizeFirstLetter = (string) => {
        if(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
      }


    return (
        <>
            <div id="myModal" className="modal fade show " style={{ display: show === true ? "block" : "none" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn" onClick={handlerClose} data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body" id="product_id">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-3 product_image">
                                        {product_details.image && product_details.image.src ? <img src={product_details.image.src} className="img-fluid"/>
                                        :
                                        <img src={"https://truth-events.com/wp-content/uploads/2019/09/dummy.jpg"} className="img-fluid" />
                                        }
                                    </div>
                                    <div className="col-md-9">
                                        <h4 className="product_name">{capitalizeFirstLetter(product_details.title)}</h4>
                                        <ul className="list-inline">
                                            {product_details.variants[0] && product_details.variants[0].sku && <li className="list-inline-item">SKU {product_details.variants[0].sku}</li>}
                                            {/* <li className="list-inline-item">{product_details.options[0].values}</li> */}
                                        </ul>
                                        <div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item">MENS</li>
                                                <li className="list-inline-item">2020</li>
                                                <li className="list-inline-item">WHITE ON WHITE</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <p><b>SELECT SIZES</b></p>
                                <div className="row" >
                                    {item && item.length > 0 &&
                                        item.map((item, index) =>
                                            <div key={index}
                                                className="col-md-2 col-sm-2 col-xs-2">
                                                <button
                                                    style={selectedSize.includes(item)
                                                        ? {
                                                            //backgroundColor: "#CDCDCD",
                                                            border: " 1px solid #0000FF"
                                                        }
                                                        : null}
                                                    value={item} type="button" className="btn btn-block btn-outline-secondary mb-2 " onClick={() => productSizeHandle(item)}>
                                                    {item}</button>
                                            </div>)}
                                </div>
                            </div>
                            <SubProductItem
                                subProduct={subProduct}
                                productSizeHandle ={productSizeHandle}
                            />
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={handlerClose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default IsloggedinHOC(DummyModal);