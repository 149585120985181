import React, { useEffect, useState } from 'react'
import { Table, Button } from "react-bootstrap";
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsloggedinHOC from '../../Components/IsLoggedinHOC';
import { useDispatch } from 'react-redux'
import { listCounterOffer, acceptOfferAction, denyOfferAction } from '../../Redux/action/CounterOffer'
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment'
import { toast } from "react-toastify"


const CounterOffers = (props) => {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const [counterOffer, setCounterOffer] = useState([]);


  const listCounterOfferData = async () => {
    setLoading(true)
    await dispatch(listCounterOffer())
      .then(
        response => {
          setLoading(false);
           setCounterOffer(response.data)
           const notifyData = response.data.filter(item => item.counter_offer_status === null )
           console.log("notifyData" ,notifyData )
           dispatch({ type: "SET_NOTIFICATIONS", payload: notifyData});
        },
        () => {
          setLoading(false);
        }
      )
      .catch(
        error => {
          console.log(error)
        }
      )
  }


  const acceptCounterOffer = async (_id , ticket_id ,product_id) => {
    setLoading(true)
    await dispatch(acceptOfferAction({_id , ticket_id , product_id}))
      .then(
        response => {
          setLoading(false);
          toast.success("Offer accepted successfull.")
          listCounterOfferData();
        },
        (error) => {
          setLoading(false);
          toast.error("Offer can't be accepted ")
        }
      )
      .catch(
        error => {
          console.log(error)
        }
      )
  }


  const denyCounterOffer = async (_id ,ticket_id ,product_id ) => {
    setLoading(true)
    await dispatch(denyOfferAction({_id ,ticket_id ,product_id }))
      .then(
        response => {
          setLoading(false);
          toast.success("Offer denied successfull.")
          listCounterOfferData();
        },
        (error) => {
          toast.error("Offer can't be denied ")
          setLoading(false);
        }
      )
      .catch(
        error => {
          console.log(error)
        }
      )
  }

  useEffect(() => {
     listCounterOfferData();
  }, [])


  const acceptHandler = (_id , ticket_id ,product_id) => {
    confirmAlert({
      title: 'Please Confirm ',
      message: 'Are you sure you want to Accept this Offer ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            acceptCounterOffer(_id , ticket_id , product_id)
          }
        },
        {
          label: 'No',
          onClick: () => {
            console.log("Not")
          }
        }
      ]
    });
  }

  const denyHandler = (_id ,ticket_id ,product_id) => {
    confirmAlert({
      title: 'Please Confirm ',
      message: 'Are you sure you want to deny this Offer ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            denyCounterOffer(_id , ticket_id ,product_id)
          }
        },
        {
          label: 'No',
          onClick: () => {
            console.log("Not")
          }
        }
      ]
    });
  }

  const productIdSpliter = (data) => {
    if (data) {
      let splitdata = data.split('-')
      return splitdata[0]
    }
  }

  const productSizeSpliter = (data) => {
    if (data) {
      let splitdata = data.split('-')
      return splitdata[1]
    }
  }

  


  return (
    < >
      <Table style={{ width: "90%", margin: "10px" }} striped bordered condensed="true" hover>
        <thead className="thead-light" >
          <tr>
            <th scope="col" >S/N</th>
            <th scope="col">DATE</th>
            <th scope="col">TICKET ID</th>
            <th scope="col">PRODUCT ID</th>
            <th scope="col">SIZE</th>
            <th scope="col" >PRODUCT NAME</th>
            <th scope="col" >OLD PRICE</th>
            <th scope="col" >OFFER PRICE</th>
            <th scope="col" >STATUS</th>
            <th scope="col" >NOTES</th>
            < th scope="col" > ACTION</th>
          </tr>
        </thead>
        <tbody>
          {counterOffer && counterOffer.length > 0 &&
            counterOffer.map((item, index) =>
              <tr key={index} >
                <td >{index + 1}</td>
                <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                <td style={{color : "blue"}} >{item.ticket_id}</td>
                <td>{productIdSpliter(item.product_id)}</td>
                <td>{productSizeSpliter(item.product_id)}</td>
                <td>{item.product_name}</td>
                <td>{item.old_price}</td>
                <td>{item.offer_price}</td>
                <td>{item.counter_offer_status ? item.counter_offer_status : "N/A"}</td>
                <td>{item.add_notes}</td>
                <td>
                  <Button variant="success" size="sm"
                    disabled={item.counter_offer_status ? true : false}
                    onClick={() => acceptHandler(item._id ,item.ticket_id ,item.product_id)} >
                    Accept</Button>{'  '}
                  <Button variant="primary" size="sm"
                    onClick={() => denyHandler(item._id ,item.ticket_id ,item.product_id )}
                    disabled={item.counter_offer_status ? true : false}>
                  Deny</Button>
                </td>
              </tr>
            )}
              {counterOffer.length == 0 && (
              <tr>
                <td className="text-center" colSpan="12">
                  <b>No data found to display.</b>
                </td>
              </tr>
            )}
        </tbody>
      </Table>
    </>
  );
}

export default IsLoadingHOC(IsloggedinHOC(CounterOffers));