import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  closeModal,
  addFooterProductAction,
  changeQtyAction,
  addApprovedData,
  addPendingData
}
  from "../../Redux/action/App"
import minusImg from '../../assets/img/minus.png'
import plusImg from '../../assets/img/plus-sign.png'
// import { getProductSneaks } from "../../Redux/action/Product"
import IsLoadingHOC from '../IsLoadingHOC'
import IsloggedinHOC from "../IsLoggedinHOC";


const SubProductItem = (props) => {
  const { setLoading, subProduct, productSizeHandle } = props;
  const { current, initialSubData, footerProductData } = useSelector(state => state.app)
  const { approvedData, pendingData } = useSelector(state => state.app)
  const { product_details } = current ? current : {}
  const [inputList, setInputList] = useState([])
  const [maxValue, setmaxValue] = useState('')
  const [minValue, setminValue] = useState('')
  const dispatch = useDispatch()

  console.log("maxValue", maxValue)
  console.log("minValue", minValue)


  useEffect(() => {
    setInputList(subProduct)
  }, [subProduct])

  useEffect(() => {
    // getProductSneaksData();
  }, [])


  // const getProductSneaksData = async () => {
  //   setLoading(true)
  //   await dispatch(product_details.variants[0] && product_details.variants[0].sku ?
  //     getProductSneaks({ sku: product_details.variants[0].sku }) :
  //     getProductSneaks({ sku: "SKU DD1391-100" }))
  //     .then(
  //       response => {
  //         setLoading(false)
  //         console.log("response.resellPrices", response.product)
  //         const obj = response.product
  //         const values = Object.values(obj)
  //         const max = Math.max(...values)
  //         const min = Math.min(...values)
  //         setmaxValue(max)
  //         setminValue(min)
  //       },
  //       (error) => {
  //         setLoading(false)
  //         console.log(error)
  //       }
  //     )
  //     .catch(
  //       error => {
  //         console.log(error)
  //       }
  //     )
  // }

  const handleIncrement = (index, name, price) => {
    const updatePrice = price + 5
    const list = [...inputList];
    list[index][name] = updatePrice;
    setInputList(list);
  }

  const handleDecrement = (index, name, price) => {
    const updatePrice = price - 5
    const list = [...inputList];
    list[index][name] = updatePrice;
    setInputList(list);
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = name === "product_price" ? parseInt(value) : value;
    setInputList(list);
  };


  const handleQty = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);

    if (value > 0) {
      const filterData = initialSubData.filter(item => item.product_id == product_details.id)
      filterData.map((item, i) => {
        if (i === index) {
          const list = [...inputList];
          list[index]['product_price'] = value * item.price;
          setInputList(list);
        }
      })
      dispatch(changeQtyAction());
    }
  };

  const addProductHandle = () => {
    const dummyArray = []
    const currentData = inputList.map(item => {
      if (item.product_price < minValue || item.product_price > maxValue) {
        item.ticket_status = "Pending"
      }
      else {
        item.ticket_status = "Approved"
      }
      return item
    })
    const submited_data = footerProductData.filter(item => item.product_id != product_details.id)
    const concatedArray = dummyArray.concat(currentData, submited_data)
    dispatch(addFooterProductAction(concatedArray))
  }

  const addViewDataHandle = () => {
    const approval_size = []
    const pending_size = []
    const dummy_approval = []
    const dummy_pending = []
    const curr_approval = []
    const curr_pending = []
    inputList.map(item => {
      if (item.product_price < minValue || item.product_price > maxValue) {
        pending_size.push(item.product_size)
      }
      else {
        approval_size.push(item.product_size)
      }
    })
    if (approval_size && approval_size.length > 0) {
      const approved_obj = {
        product_name: inputList[0].product_name,
        product_image: inputList[0].product_image,
        product_size: [...approval_size],
        product_id: inputList[0].product_id
      }
      curr_approval.push(approved_obj)
    }
    if (pending_size && pending_size.length > 0) {
      const pending_obj = {
        product_name: inputList[0].product_name,
        product_image: inputList[0].product_image,
        product_size: [...pending_size],
        product_id: inputList[0].product_id
      }
      curr_pending.push(pending_obj)
    }
    const submited_approval = approvedData.filter(item => item.product_id != product_details.id)
    const final_approval = dummy_approval.concat(curr_approval, submited_approval)
    const submited_pending = pendingData.filter(item => item.product_id != product_details.id)
    const final_pending = dummy_pending.concat(curr_pending, submited_pending)

    dispatch(addApprovedData(final_approval));
    dispatch(addPendingData(final_pending));
  }


  const disableChecker = (inputList) => {
    const filterData = inputList.filter(item => !item.product_price || item.product_price < 0)
    if (filterData && filterData.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  const addSubProducts = () => {
    addProductHandle();
    addViewDataHandle();
    dispatch(closeModal({ isModalOpen: false, }))
  }

  const removeSubProducts = (size) => {
    productSizeHandle(size)
  }

  return (
    <>
      <div className="product_note" style={{ display: inputList && inputList.length > 0 ? "block" : "none" }}>
        <p><b>ADD DETAILS</b></p>
        <p>Input the quantity and condition of your item(s) and then set a price. Our suggested price is based on
          recent sales. Stay within the pre-approved range to list your item(s) immediately.
          Please note, anything marked “Special Box” should only refer to rare or limited edition packaging.
          Anything marked “Sample” should only refer to a sample directly from the factory.</p>
        <p>Please note, anything marked “Special Box” should only refer to rare or limited edition packaging.
          Anything marked “Sample” should only refer to a sample directly from the factory.</p>

      </div>
      {inputList && inputList.map((item, index) => {
        return (
          <div key={index} id="product_list">
            <div className="product--cart" id="cart_inner-4">
              <div className="product--cart--inner">
                <div className="product--size">
                  <div className="product--size-data">
                    <h3>Size</h3>
                    <h5 id="product_specific_size" className="all_product_size" value="4">{item.product_size}</h5>
                  </div>
                </div>
                <div className="product--quantity" id="product_quantity-4">
                  <div className="product-q-inner">
                    <label>Quantity</label>
                    <input id="product_quantity"
                      type="text"
                      name="product_quantity"
                      onChange={e => handleQty(e, index)}
                      value={item.product_quantity}
                      disabled={!item.product_price ? true : false}
                    />
                  </div>
                </div>
                <div className="product--select">
                  <div className="product-slect-inner">
                    <label>Choose one</label>
                    <select name="product_type"
                      onChange={e => handleInputChange(e, index)}
                      value={item.product_type}
                    >
                      <option value="No Flaws">No Flaws</option>
                      <option value="Sample item">Sample</option>
                      <option value="Special Packaging">Special Box</option>
                    </select>
                  </div>
                </div>
                <div className="product--increment">
                  <div className="product-increment-inner">
                    <div className="minus" name="product_price" id={item.product_size} onClick={() => handleDecrement(index, "product_price", item.product_price)} ><img src={minusImg} /></div>
                    <div className="increment--input">
                      <input
                        style={item.product_price < minValue || item.product_price > maxValue ? { color: "#FF0000" } : {}}
                        id={item.product_size}
                        value={item.product_price}
                        name="product_price"
                        onChange={e => handleInputChange(e, index)}
                        type="number" />
                    </div>
                    <div className="plus" name="product_price" id={item.product_size} onClick={() => handleIncrement(index, "product_price", item.product_price)} ><img src={plusImg} /></div>
                  </div>
                  <input type="hidden" value={item.ticket_status}  ></input>

                  {item.product_price >= minValue && item.product_price <= maxValue &&
                    <div style={{ fontSize: "12px", color: "rgb(137, 139, 142)" }} className="pre--range">
                      Pre-approved range : ${minValue}-${maxValue}
                    </div>}
                  {!item.product_price &&
                    <div style={{ fontSize: "12px", color: "#FF0000" }} className="pre--range">
                      Please enter the price
                    </div>}
                  {item.product_price < minValue && item.product_price > 0 &&
                    <div style={{ fontSize: "12px", color: "#FF0000" }} className="pre--range">
                      Below pre-approved minimum : ${minValue}
                    </div>}
                  {item.product_price > maxValue &&
                    <div style={{ fontSize: "12px", color: "#FF0000" }} className="pre--range">
                      Over pre-approved maximum : ${maxValue}
                    </div>}

                </div>
              </div>
              <div className="remove--product ">
                <button onClick={() => removeSubProducts(item.product_size)} style={{ color: "blue" }} >
                  Remove
                </button>
              </div>
            </div></div>
        )
      })
      }
      <div className="add_product">
        <button className="btn btn-lg mb-2 " id="add_products" role="button"
          onClick={() => addSubProducts()}
          disabled={disableChecker(inputList)}
        >
          Save products {` (${inputList.length})`}</button>
      </div>
    </>
  )
}
export default IsLoadingHOC(IsloggedinHOC(SubProductItem));