import React ,{ useState, useEffect} from 'react'
import { useDispatch ,useSelector } from 'react-redux'
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsloggedinHOC from '../IsLoggedinHOC'
import { listCounterOffer} from '../../Redux/action/CounterOffer'



const  Notification = (props) => {
    const { setLoading } = props;
    const dispatch = useDispatch();
    const {notifications} = useSelector(state =>state.app)

    const listCounterOfferData = async () => {
         setLoading(true)
        await dispatch(listCounterOffer())
          .then(
            response => {
               setLoading(false);
               const notifyData = response.data.filter(item => item.counter_offer_status === null )
               dispatch({ type: "SET_NOTIFICATIONS", payload: notifyData});
            },
            () => {
              setLoading(false);
            }
          )
          .catch(
            error => {
              console.log(error)
            }
          )
      }

      useEffect(() => {
        listCounterOfferData();
      }, [])


    return (
        <>
            <span>
                <sup>{notifications && notifications.length > 0 ? notifications.length : "" }</sup>
            </span>
        </>
    )
}

export default IsLoadingHOC(IsloggedinHOC(Notification));