import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Product from '../../Container/Seller/Product';

function Home() {
    const role = useSelector(state => state.auth.user);
    return (
        <div>
            <Product />
        </div>
    )
}

export default Home