import {authAxios} from "../../Config/axios"


export const listCounterOffer = () => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/auth/seller/list-counter-offer`)
            .then(
                response => {
                    resolve(response.data)
                   
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const acceptOfferAction = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/auth/seller/accept-counter-offer`, data )
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}


export const denyOfferAction = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/auth/seller/deny-counter-offer`, data )
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}