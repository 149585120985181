import React, { useState, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import { register } from '../../Redux/action/Auth';
import { toast } from "react-toastify"
import IsLoadingHOC from '../IsLoadingHOC';
import countryList from 'react-select-country-list'
import Select from 'react-select'
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Signup = (props) => {
    const { register, setLoading } = props
    const history = useHistory()
    const [country, setCountry] = useState('')
    const options = useMemo(() => countryList().getData(), [])



    const changeHandler = option => {
        setCountry(option)
        const value = option.value;
        const label = option.label
        const final_value = `${value}/${label}`
        return final_value
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            address1: '',
            address2: '',
            city: '',
            zip: '',
            country_code: '',
            password: '',
            confirm_password: '',
            role: "SELLER"
        },

        validationSchema: Yup.object({
            name: Yup.string()
                .required("Username is required")
                .min(5, 'Username must be at least 5 characters'),
            email: Yup.string()
                .required("Email is required")
                .email('Email is invalid')
                .matches('^\\S*$', '* This field cannot contain only blankspaces.'),
            mobile: Yup.string()
                .required("Mobile number is required"),
            address1: Yup.string()
                .required("Address Line 1 is required"),
            city: Yup.string()
                .required("City is required"),
            zip: Yup.string()
                .required("Zip code is required"),
            country_code: Yup.string()
                .required("Country code is required"),
            password: Yup.string()
                .required("Password is required")
                .min(8, 'Password must be at least 8 characters'),
            confirm_password: Yup.string()
                .required("Confirm Password is required")
                .oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
        }),
        //   validation schema ends
        onSubmit: async values => {
            console.log('on Submit hit ------', values);
            const fieldValues = values
            setLoading(true)
            await register(fieldValues)
                .then(
                    response => {
                        setLoading(false)
                        toast.success(response.message)
                        history.push("/login")
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data.message);
                    }
                )
                .catch(
                    error => console.log(error)
                )
        },
    });


    return (
        <div className="register-wrapper">
            <div className="register-inner">
                <h3>Stay Fresh</h3>
                <form onSubmit={formik.handleSubmit} >
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Enter name" />
                        {formik.touched.name && formik.errors.name ? (<span className="text-danger " >{formik.errors.name}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>Email address</label>
                        <input
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            type="email" className="form-control" placeholder="Enter email" />
                        {formik.touched.email && formik.errors.email ? (<span className="text-danger " >{formik.errors.email}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>Mobile</label>
                        <input
                            name="mobile"
                            onChange={formik.handleChange}
                            value={formik.values.mobile}
                            onBlur={formik.handleBlur}
                            type="number" className="form-control" placeholder="Enter Mobile number" />
                        {formik.touched.mobile && formik.errors.mobile ? (<span className=" text-danger " >{formik.errors.mobile}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>Address Line 1</label>
                        <input
                            name="address1"
                            onChange={formik.handleChange}
                            value={formik.values.address1}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Enter Address line 1" />
                        {formik.touched.address1 && formik.errors.address1 ? (<span className=" text-danger " >{formik.errors.address1}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>Address Line 2</label>
                        <input
                            name="address2"
                            onChange={formik.handleChange}
                            value={formik.values.address2}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Enter Address line 2" />
                        {formik.touched.address2 && formik.errors.address2 ? (<span className=" text-danger " >{formik.errors.address2}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>City</label>
                        <input
                            name="city"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Enter City " />
                        {formik.touched.city && formik.errors.city ? (<span className=" text-danger " >{formik.errors.city}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>Zip code</label>
                        <input
                            name="zip"
                            onChange={formik.handleChange}
                            value={formik.values.zip}
                            onBlur={formik.handleBlur}
                            type="text" className="form-control" placeholder="Enter Zip code" />
                        {formik.touched.zip && formik.errors.zip ? (<span className=" text-danger " >{formik.errors.zip}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>Country code</label>
                        <Select name="country_code" options={options} value={country} onChange={(option) => {
                            formik.setFieldValue('country_code', changeHandler(option));
                        }} />
                        {formik.touched.country_code && formik.errors.country_code ? (<span className=" text-danger " >{formik.errors.country_code}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            type="password" className="form-control" placeholder="Enter password" />
                        {formik.touched.password && formik.errors.password ? (<span className=" text-danger " >{formik.errors.password}</span>) : null}
                    </div>
                    <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                            name="confirm_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirm_password}
                            type="password" className="form-control" placeholder="Enter confirm password" />
                        {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className=" text-danger " >{formik.errors.confirm_password}</span>) : null}
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">Register</button>
                    <p className="forgot-password text-left">
                        Already have an account <Link to="/login">Login</Link>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default connect(null, { register })(IsLoadingHOC(Signup))