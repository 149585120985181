import { authAxios } from "../../Config/axios"


export const getProductList = (currentPage, limit, searchName) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        if (searchName) {
            await authAxios().post(`/auth/seller/search-all-product?searchName=${searchName}&page=${currentPage}&size=${limit}`)
                .then(
                    response => {
                        resolve(response.data)
                        _dispatch({ type: "PRODUCT_DATA", payload: response.data.data });
                    },
                    error => {
                        reject(error)
                    }
                )
                .catch(
                    error => {
                        console.log(error);
                    }
                )
        }
        else {
            await authAxios().post(`/auth/seller/list-all-products?page=${currentPage}&size=${limit}`)
                .then(
                    response => {
                        resolve(response.data)
                        _dispatch({ type: "PRODUCT_DATA", payload: response.data.data });
                    },
                    error => {
                        reject(error)
                    }
                )
                .catch(
                    error => {
                        console.log(error);
                    }
                )
        }
    })

}

export const addProductItem = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/auth/seller/create-seller-ticket", data)
            .then(
                response => {
                    resolve(response.data)

                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const getSellerTicket = id => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/auth/seller/get-seller-ticket/${id}`)
            .then(
                response => {
                    resolve(response.data)

                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const listSellerTicket = () => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/auth/seller/list-seller-ticket`)
            .then(
                response => {
                    resolve(response.data)

                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}


export const getProductSneaks = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/products/list-all-products", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}

export const updatePendingItem = (ticket_id, data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/auth/seller/update-pending-item/${ticket_id}`, data)
            .then(
                response => {
                    resolve(response.data)

                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}


export const addManuallyProductAction = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post("/auth/seller/add-manually-product", data)
            .then(
                response => {
                    resolve(response.data)

                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log(error);
                }
            )
    })
}




