import { Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import PrivateRoute from './PrivateRoute';
import PrivateLayout from '../Layout/Private';
import PublicLayout from '../Layout/Public';
import Login from '../Components/Login/Login';
import Home from '../Components/Home/Home';
import SellerTicketDash from '../Container/SellerTicketDash';
import TicketsListPage from '../Container/TicketListPage';
import CounterOffers from '../Container/CounterOffer';
import Signup from '../Components/Signup';
import UserProfile from '../Components/UserProfile';
const Routing = () => {
    return (
        <Switch>
            <AppRoute
                exact
                path="/login"
                component={Login}
                layout={PublicLayout} />
            <AppRoute
                exact
                path="/register"
                component={Signup}
                layout={PublicLayout} />
            <PrivateRoute
                exact
                path="/"
                component={Home}
                layout={PrivateLayout} />
            <PrivateRoute
                exact
                path="/seller/ticket/:id"
                component={SellerTicketDash}
                layout={PrivateLayout} />
            <PrivateRoute
                exact
                path="/seller/tickets"
                component={TicketsListPage}
                layout={PrivateLayout} />
            <PrivateRoute
                exact
                path="/seller/offers"
                component={CounterOffers}
                layout={PrivateLayout} />
            <PrivateRoute
                exact
                path="/user/profile"
                component={UserProfile}
                layout={PrivateLayout} />
            <Route
                path="*"
                component={() => (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <h1 style={{ color: "#000", fontSize: "2rem" }}>Page Not Found!!</h1>
                    </div>
                )}
            />
        </Switch>
    )
}
export default Routing;
