import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsloggedinHOC from '../../Components/IsLoggedinHOC';
import { useDispatch } from 'react-redux'
import { listSellerTicket } from '../../Redux/action/Product'
import moment from 'moment'
import './tickets.css'



const TicketListPage = (props) => {
   const { setLoading } = props;
   const dispatch = useDispatch();
   const [sellerTickets, setSellerTickets] = useState([]);


   const listSellerTicketData = async () => {
      setLoading(true)
      await dispatch(listSellerTicket())
         .then(
            response => {
               setLoading(false);
               let dummyArry = []
               response.data.map((item, i) => {
                  item.product_details.map((product, index) => {
                     const dummyObj = {
                        ...product.product_details,
                        date: item.date,
                        seller_id: item.seller_id,
                        ticket_id: item.ticket_id,
                        _id: item._id
                     }
                     dummyArry.push(dummyObj)
                  })
               })

               setSellerTickets(response.data)
            },
            () => {
               setLoading(false);
            }
         )
         .catch(
            error => {
               console.log(error)
            }
         )
   }

   useEffect(() => {
      listSellerTicketData();
   }, [])

   const handlerCount = (products) => {
      if (products && products.length > 0) {
         const total = products.reduce((previousValue, currentValue) => previousValue + currentValue.product_details.product_price, 0)
         return total
      }
   }
   const getApprovedItem = (products) => {
      if (products && products.length > 0) {
         const filterData = products.filter(item => item.product_details.ticket_status === "Approved")
         return filterData.length
      }
   }
   const getProductName = (products) => {
      if (products && products.length > 0) {
         return products[0].product_details.product_name
      }
   }
   const getProductSKU = (products) => {
      if (products && products.length > 0) {
         return products[0].product_details.product_sku
      }
   }

   return (
      < >
         <div className="container">
            <div className="accordion" id="accordionExample">
               {sellerTickets && sellerTickets.length > 0 &&
                  sellerTickets.map((item, index) =>
                     <div key={item._id} className="accordion-item mb-3">
                        <h2 className="accordion-header" id={"headingOne" + index + 1} >
                           <div className="accordion-button create_custome_acc_btn" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index + 1} aria-expanded="false" aria-controls={"collapseOne" + index + 1}>
                              <div className="table-responsive">
                                 <table className="table table-borderless mb-0 accordion_heading_table">
                                    <tbody>
                                       <tr>
                                          <td>Ticket-ID
                                             <Link
                                                style={{ color: '#0000FF', textDecoration: "none" }}
                                                to={`/seller/ticket/${item.ticket_id}`} >
                                                <p>{item.ticket_id}</p>
                                             </Link>
                                          </td>
                                          <td>
                                             # of products
                                             <p>{getProductName(item.product_details)}</p>
                                          </td>
                                          <td>
                                             Approved items
                                             <p>{getApprovedItem(item.product_details)}/{item.product_details.length}</p>
                                          </td>
                                          <td>
                                             listed value
                                             <p>dy</p>
                                          </td>
                                          <td>
                                             total payout
                                             <p>{handlerCount(item.product_details)}</p>
                                          </td>
                                          <td>
                                             date
                                             <p>{moment(item.date).format("DD/MM/YYYY")}</p>
                                          </td>
                                          <td>
                                             date
                                             <p>{moment(item.date).format("DD/MM/YYYY")}</p>
                                          </td>
                                          <td>
                                             last modified
                                             <p>{moment(item.date).format("DD/MM/YYYY")}</p>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </h2>
                        <div id={"collapseOne" + index + 1} className = {`accordion-collapse collapse ${index === 0 && "show"}`} aria-labelledby={"headingOne" + index + 1} data-bs-parent="#accordionExample">
                           <div className="accordion-body costmize_body_content">
                              <div className="table-responsive">
                                 <table className="table table-borderless">
                                    <thead>
                                       <tr>
                                          <th>#</th>
                                          <th>Image</th>
                                          <th>Name</th>
                                          <th>sku</th>
                                          <th>size</th>
                                          <th>conduction</th>
                                          <th>Listed price</th>
                                          <th>payout</th>
                                          <th>status</th>
                                          <th>action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <th scope="row">1</th>
                                          <td>Mark</td>
                                          <td>{getProductName(item.product_details)}</td>
                                          <td>{getProductSKU(item.product_details)}</td>
                                          <td>
                                             <ul style={{ listStyleType: "none" }} >
                                                {item.product_details.map((product, i) =>
                                                   <li key={i} >{product.product_details.product_size}</li>)}
                                             </ul>
                                          </td>
                                          <td>@mdo md </td>
                                          <td>
                                             <ul style={{ listStyleType: "none" }} >
                                                {item.product_details.map((product, i) =>
                                                   <li key={i} >{product.product_details.product_price}</li>)}
                                             </ul>
                                          </td>
                                          <td>{handlerCount(item.product_details)}</td>
                                          <td>
                                             <ul style={{ listStyleType: "none" }} >
                                                {item.product_details.map((product, i) =>
                                                   <li key={i} >{product.product_details.ticket_status}</li>)}
                                             </ul>
                                          </td>
                                          <td>@mmdom dodo</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
            </div>
         </div>
      </>
   );
}
export default IsLoadingHOC(IsloggedinHOC(TicketListPage));