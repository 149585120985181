import React, {useState ,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { getSellerTicket } from '../../Redux/action/Product'
import { useDispatch , useSelector } from 'react-redux'
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsloggedinHOC from '../../Components/IsLoggedinHOC'
import moment from 'moment'
import approvedImg from '../../assets/img/approved.png'
import pendingImg from '../../assets/img/under-reviw.png'
import crossImg from '../../assets/img/cross.png'
import Edit_Model from './Edit_Model'
import { getModuleData } from '../../Redux/action/App'


const SellerTicket = (props) => {
    const {setLoading} = props
    const dispatch = useDispatch();
    const params = useParams()
    const [createdDate , setCreatedDate] = useState(new Date())
    const [updatedeDate , setUpdatedeDate] = useState(new Date())
    const [pendingData ,setPendingData ] = useState([]);
    const [approvalData ,setApprovalData ] = useState([]);
    const [rejectedData ,setRejectedData ] = useState([]);
    const [expiredData ,setExpiredData ] = useState([]);
    const [modifiedData ,setModifiedData ] = useState([]);
    const modal = useSelector(state => state.app)
   


    const getSellerTicketData = async () => {
         setLoading(true);
        await dispatch(getSellerTicket(params.id))
            .then(
                response => {
                    setLoading(false);
                    setCreatedDate(response.data[0].data)
                    setUpdatedeDate(response.data[0].data)
                     const data = response.data[0].product_details
                     const approval_data = data.filter(item => item.product_details.ticket_status === "Approved")
                     const pending_data = data.filter(item => item.product_details.ticket_status === "Pending")
                     const rejected_data = data.filter(item => item.product_details.ticket_status === "Rejected")
                     const expired_data = data.filter(item => item.product_details.ticket_status === "Expired")
                     const modified_data = data.filter(item => item.product_details.ticket_status === "Modified")
                     setApprovalData(approval_data)
                     setPendingData(pending_data)
                     setRejectedData(rejected_data)
                     setExpiredData(expired_data)
                     setModifiedData(modified_data)
                },
                (error) => {
                    console.log(false);
                     setLoading(false);
                }
            )
            .catch(
                error => console.log(error)
            )
    }
    useEffect(() => {
        getSellerTicketData();
    }, []);

    const handleModal = (_id) => {
        const product_data = []
           pendingData.map(item => {
             if(item.product_details.product_id == _id ){
                product_data.push(item.product_details)
             }
        })
        let modelObj = {
            isModalOpen: true,
            product_details: product_data,
            show: true,
            tiket_id : params.id
        }
        dispatch(getModuleData(modelObj))
    }

    return (
        <>
            <section className="ticket--status">
                <div className="ticket--status--wrapper">
                    <div className="ticket--number-wrapper">
                        <div className="ticket--number">
                            <span>TICKET</span>
                            {/* <span>#14675878</span> */}
                            {params.id}
                        </div>
                        <div className="ticket--status-data">
                            <div><span>Ticket Status</span></div>
                            <div><span>APPROVED</span></div>
                            <div><span>Created</span></div>
                            <div><span>{moment(createdDate).format("DD/MM/YYYY")}</span></div>
                            <div><span>Submitted</span></div>
                            <div><span>{moment(updatedeDate).format("DD/MM/YYYY")}</span></div>
                        </div>
                    </div>
                    <div className="qr--code">
                        <img src="img/qr--code.jpg" alt="" />
                    </div>
                </div>
            </section>
            <section className="items--shipment">
                <div className="items--shipment--wrapper">
                    <div className="shipment--tab--header">
                        <div className="tab--list">
                            <ul>
                                <li className="active--list">Items</li>
                                <li>Shipments <span>(0)</span></li>
                            </ul>
                        </div>
                        <tab className="list--status">
                            <div className="tab--list--group">
                                <div><span>PENDING</span> <span>{pendingData && pendingData.length > 0 ? pendingData.length : "0" }</span></div>
                                <div><span>MODIFIED</span> <span>{modifiedData && modifiedData.length > 0 ? modifiedData.length : "0" }</span></div>
                                <div><span>APPROVED</span> <span>{approvalData && approvalData.length > 0 ? approvalData.length : "0"  }</span></div>
                                <div><span>REJECTED</span> <span>{rejectedData && rejectedData.length > 0 ? rejectedData.length : "0"  }</span></div>
                                <div><span>EXPIRED</span> <span>{expiredData && expiredData.length > 0 ? expiredData.length : "0"  }</span></div>
                            </div>
                        </tab>
                    </div>
                    {approvalData && approvalData.length > 0 &&
                    <div style={{marginTop : "30px"}}   className="tab--content-shipment">
                        <div className="tab--data1 tab--content-main active--tab">
                            <div className="pre--aproved">
                                <div className="pre--aproved--text">
                                    <img src= {approvedImg} alt="" />
                                    <h4>approved</h4>
                                </div>
                            </div>
                            <p>
                            The following items have been pre-approved and are ready to be inspected. Ship these items to our warehouse or drop them off at a retail location within 14 days of approval. After 14 days, this ticket will expire and you will be required to submit a new ticket for pricing approval.
                            </p>
                            <div className="tab--btn--inner">
                                <button type="button" className="btn--tab">Ship Items</button>
                                
                            </div>
                           {
                             approvalData.map((item ,index) =>
                            <div key = {index}  className="product--details--tab">
                                <div className="img-product">
                                    <img src="img/shose.png" alt="" />
                                    <span>1</span>
                                </div>
                                <div className="modal--name">
                                    <h3>MODEL</h3>
                                    <span>{item.product_details.product_name}</span>
                                </div>
                                <div className="modal--SKU">
                                    <h3>SKU</h3>
                                    <span>{item.product_details?.product_sku  ? item.product_details?.product_sku : 'CW2288 111'} </span>
                                </div>
                                <div className="modal--name">
                                    <h3>SIZE</h3>
                                    <span>{item.product_details.product_size}</span>
                                </div>
                                <div className="modal--PRICE">
                                    <h3>PRICE</h3>
                                    <span>${item.product_details.product_price}</span>
                                </div>
                                <div className="modal--CONDITION">
                                    <h3>CONDITION</h3>
                                    <span>No Flaws </span>
                                </div>
                            </div>
                              )}
                        </div>
                    </div>}
                    {pendingData && pendingData.length > 0 &&
                    <div style={{marginTop : "30px"}}  className="tab--content-shipment">
                        <div className="tab--data1 tab--content-main active--tab">
                            <div className="pre--aproved">
                                <div className="pre--aproved--text">
                                    <img src= {pendingImg} alt="" />
                                    <h4>Pending</h4>
                                </div>
                            </div>
                            <p>
                            The following items have not been pre-approved and need to be reviewed by our consignment team (this can take up to 48 hours). You can still edit qualifying items for pre-approval.
                            </p>
                           {
                             pendingData.map((item ,index) =>
                            <div key = {index}  className="product--details--tab">
                                <div className="img-product">
                                    <img src="img/shose.png" alt="" />
                                    <span>1</span>
                                </div>
                                <div className="modal--name">
                                    <h3>MODEL</h3>
                                    <span>{item.product_details.product_name}</span>
                                </div>
                                <div className="modal--SKU">
                                    <h3>SKU</h3>
                                    <span>{item.product_details?.product_sku  ? item.product_details?.product_sku : 'CW2288 111'} </span>
                                </div>
                                <div className="modal--name">
                                    <h3>SIZE</h3>
                                    <span>{item.product_details.product_size}</span>
                                </div>
                                <div className="modal--PRICE">
                                    <h3>PRICE</h3>
                                    <span>${item.product_details.product_price}</span>
                                </div>
                                <div className="modal--CONDITION">
                                    <h3>CONDITION</h3>
                                    <span>No Flaws </span>
                                </div>
                                <div className='edit-btn' >
                                <button  onClick={() => handleModal(item.product_details.product_id)}   className="modal--CONDITION" >Edit</button>
                                </div>
                            </div>
                              )}
                        </div>
                    </div>}
                    {modifiedData && modifiedData.length > 0 &&
                    <div style={{marginTop : "30px"}}  className="tab--content-shipment">
                        <div className="tab--data1 tab--content-main active--tab">
                            <div className="pre--aproved">
                                <div className="pre--aproved--text">
                                    <img src= {pendingImg} alt="" />
                                    <h4>Modified</h4>
                                </div>
                            </div>
                            <p>
                            The following items were modified, please click edit to accept or reject the change.                            </p>
                           {
                             modifiedData.map((item ,index) =>
                            <div key = {index}  className="product--details--tab">
                                <div className="img-product">
                                    <img src="img/shose.png" alt="" />
                                    <span>1</span>
                                </div>
                                <div className="modal--name">
                                    <h3>MODEL</h3>
                                    <span>{item.product_details.product_name}</span>
                                </div>
                                <div className="modal--SKU">
                                    <h3>SKU</h3>
                                    <span>{item.product_details?.product_sku  ? item.product_details?.product_sku : 'CW2288 111'} </span>
                                </div>
                                <div className="modal--name">
                                    <h3>SIZE</h3>
                                    <span>{item.product_details.product_size}</span>
                                </div>
                                <div className="modal--PRICE">
                                    <h3>PRICE</h3>
                                    <span>${item.product_details.product_price}</span>
                                </div>
                                <div className="modal--CONDITION">
                                    <h3>CONDITION</h3>
                                    <span>No Flaws </span>
                                </div>
                                <button className="modal--CONDITION" >Edit</button>
                            </div>
                              )}
                        </div>
                    </div>}
                    {expiredData && expiredData.length > 0 &&
                    <div style={{marginTop : "30px"}}  className="tab--content-shipment">
                        <div className="tab--data1 tab--content-main active--tab">
                            <div className="pre--aproved">
                                <div className="pre--aproved--text">
                                    <img src= {crossImg} alt="" />
                                    <h4>Expired</h4>
                                </div>
                            </div>
                            <p>
                            The following items are expired, because they were not sent in due time.
                            </p>
                           {
                             expiredData.map((item ,index) =>
                            <div key = {index}  className="product--details--tab">
                                <div className="img-product">
                                    <img src="img/shose.png" alt="" />
                                    <span>1</span>
                                </div>
                                <div className="modal--name">
                                    <h3>MODEL</h3>
                                    <span>{item.product_details.product_name}</span>
                                </div>
                                <div className="modal--SKU">
                                    <h3>SKU</h3>
                                    <span>{item.product_details?.product_sku  ? item.product_details?.product_sku : 'CW2288 111'} </span>
                                </div>
                                <div className="modal--name">
                                    <h3>SIZE</h3>
                                    <span>{item.product_details.product_size}</span>
                                </div>
                                <div className="modal--PRICE">
                                    <h3>PRICE</h3>
                                    <span>${item.product_details.product_price}</span>
                                </div>
                                <div className="modal--CONDITION">
                                    <h3>CONDITION</h3>
                                    <span>No Flaws </span>
                                </div>
                            </div>
                              )}
                        </div>
                    </div>}
                </div>
            </section>
            {modal.current.isModalOpen
                ? <Edit_Model getSellerTicketData = {getSellerTicketData} />
                : ""}
        </>
    )
}

export default IsLoadingHOC(IsloggedinHOC(SellerTicket));