import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../Redux/action/App"
import { updatePendingItem } from '../../Redux/action/Product'
import minusImg from '../../assets/img/minus.png'
import plusImg from '../../assets/img/plus-sign.png'
// import { getProductSneaks } from "../../Redux/action/Product"
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsloggedinHOC from "../../Components/IsLoggedinHOC";
import { toast } from "react-toastify";


const SubEditProduct = (props) => {
    const { setLoading, getSellerTicketData } = props;
    const { current } = useSelector(state => state.app)
    const { product_details, tiket_id } = current ? current : {}
    const [inputList, setInputList] = useState([])
    const [maxValue, setmaxValue] = useState('298')
    const [minValue, setminValue] = useState('149')
    const dispatch = useDispatch()


    useEffect(() => {
        // getProductSneaksData();
        setInputList(product_details)
    }, [])


    // const getProductSneaksData = async () => {
    //     setLoading(true)
    //     await dispatch(product_details[0]?.product_sku ?
    //         getProductSneaks({ sku: product_details[0]?.product_sku }) :
    //         getProductSneaks({ sku: "SKU DD1391-100" }))
    //         .then(
    //             response => {
    //                 setLoading(false)
    //                 console.log("response.resellPrices", response.product)
    //                 const obj = response.product
    //                 const values = Object.values(obj)
    //                 const max = Math.max(...values)
    //                 const min = Math.min(...values)
    //                 setmaxValue(max)
    //                 setminValue(min)
    //             },
    //             (error) => {
    //                 setLoading(false)
    //                 console.log(error)
    //             }
    //         )
    //         .catch(
    //             error => {
    //                 console.log(error)
    //             }
    //         )
    // }

    const handleIncrement = (index, name, price) => {
        const updatePrice = price + 5
        const list = [...inputList];
        list[index][name] = updatePrice;
        setInputList(list);
    }

    const handleDecrement = (index, name, price) => {
        const updatePrice = price - 5
        const list = [...inputList];
        list[index][name] = updatePrice;
        setInputList(list);
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = name === "product_price" ? parseInt(value) : value;
        setInputList(list);
    };

    const saveProductHandle = () => {
        const currentData = inputList.map(item => {
            if (item.product_price < minValue || item.product_price > maxValue) {
                item.ticket_status = "Pending"
            }
            else {
                item.ticket_status = "Approved"
            }
            return item
        })
        const payload = {
            product_details: [...currentData]
        }
        dispatch(updatePendingItem(tiket_id, payload))
            .then(
                response => {
                    setLoading(false);
                    console.log(response.data)
                    toast.success(response.message)
                    getSellerTicketData()
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data.message)
                }
            )
            .catch(
                error => {
                    console.log(error)
                }
            )

    }


    const disableChecker = (inputList) => {
        const filterData = inputList.filter(item => !item.product_price || item.product_price < 0)
        if (filterData && filterData.length > 0) {
            return true
        }
        else {
            return false
        }
    }

    const saveSubProducts = () => {
        saveProductHandle();
        dispatch(closeModal({ isModalOpen: false, }))
    }

    return (
        <>
            <div className="product_note" style={{ display: inputList && inputList.length > 0 ? "block" : "none" }}>
                <p><b>ADD DETAILS</b></p>
                <p>Input the quantity and condition of your item(s) and then set a price. Our suggested price is based on
                    recent sales. Stay within the pre-approved range to list your item(s) immediately.
                    Please note, anything marked “Special Box” should only refer to rare or limited edition packaging.
                    Anything marked “Sample” should only refer to a sample directly from the factory.</p>
                <p>Please note, anything marked “Special Box” should only refer to rare or limited edition packaging.
                    Anything marked “Sample” should only refer to a sample directly from the factory.</p>

            </div>
            {inputList && inputList.map((item, index) => {
                return (
                    <div key={index} id="product_list">
                        <div className="product--cart" id="cart_inner-4">
                            <div className="product--cart--inner">
                                <div className="product--size">
                                    <div className="product--size-data">
                                        <h3>Size</h3>
                                        <h5 id="product_specific_size" className="all_product_size" value="4">{item.product_size}</h5>
                                    </div>
                                </div>
                                <div className="product--quantity" id="product_quantity-4">
                                    <div className="product-q-inner">
                                        <label>Quantity</label>
                                        <input id="product_quantity"
                                            type="text"
                                            name="product_quantity"
                                            onChange={() => { }}
                                            value={item.product_quantity}
                                            disabled={!item.product_price ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="product--select">
                                    <div className="product-slect-inner">
                                        <label>Choose one</label>
                                        <select name="product_type"
                                            onChange={e => handleInputChange(e, index)}
                                            value={item.product_type}
                                        >
                                            <option value="No Flaws">No Flaws</option>
                                            <option value="Sample item">Sample</option>
                                            <option value="Special Packaging">Special Box</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="product--increment">
                                    <div className="product-increment-inner">
                                        <div className="minus" name="product_price" id={item.product_size} onClick={() => handleDecrement(index, "product_price", item.product_price)} ><img src={minusImg} /></div>
                                        <div className="increment--input">
                                            <input
                                                style={item.product_price < minValue || item.product_price > maxValue ? { color: "#FF0000" } : {}}
                                                id={item.product_size}
                                                value={item.product_price}
                                                name="product_price"
                                                onChange={e => handleInputChange(e, index)}
                                                type="number" />
                                        </div>
                                        <div className="plus" name="product_price" id={item.product_size} onClick={() => handleIncrement(index, "product_price", item.product_price)} ><img src={plusImg} /></div>
                                    </div>
                                    <input type="hidden" value={item.ticket_status}  ></input>

                                    {item.product_price >= minValue && item.product_price <= maxValue &&
                                        <div style={{ fontSize: "12px", color: "rgb(137, 139, 142)" }} className="pre--range">
                                            Pre-approved range : ${minValue}-${maxValue}
                                        </div>}
                                    {!item.product_price &&
                                        <div style={{ fontSize: "12px", color: "#FF0000" }} className="pre--range">
                                            Please enter the price
                                        </div>}
                                    {item.product_price < minValue && item.product_price > 0 &&
                                        <div style={{ fontSize: "12px", color: "#FF0000" }} className="pre--range">
                                            Below pre-approved minimum : ${minValue}
                                        </div>}
                                    {item.product_price > maxValue &&
                                        <div style={{ fontSize: "12px", color: "#FF0000" }} className="pre--range">
                                            Over pre-approved maximum : ${maxValue}
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            }
            <div className="add_product">
                <button className="btn btn-lg mb-2 " id="add_products" role="button"
                    onClick={() => saveSubProducts()}
                    disabled={disableChecker(inputList)}
                >
                    Save products {` (${inputList.length})`}</button>
            </div>
        </>
    )
}
export default IsLoadingHOC(IsloggedinHOC(SubEditProduct));