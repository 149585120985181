import Header from "./Header"
import Footer from "./Footer"

const PrivateLayout = ({ children }) => {
    return (
        <div className="wrapper">
            <Header />
            <div className="body">
                {children}
            </div>
            <Footer />

        </div>
    )
}

export default PrivateLayout;
