import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import IsloggedinHOC from '../IsLoggedinHOC';


const PopupModel = (props) => {
  const { show, hideModel } = props
  const history = useHistory();

  const { notifications } = useSelector(state => state.app)

  const clickHandler = () => {
    hideModel();
    history.push('/seller/offers')

  }

  return (
    <>
      <Modal show={show} onHide={hideModel}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped" >
            <thead>
              <tr>
                <td>S/N</td>
                <td>Product Name</td>
                <td>Ticket Id</td>
                <td>Old Price</td>
                <td>Offer Price</td>
              </tr>
            </thead>
            <tbody>
              {notifications && notifications.length > 0 &&
                notifications.map((item, index) =>
                  <tr key = {index + 1} >
                    <td>{index+1}</td>
                    <td>{item.product_name}</td>
                    <td  style={{color : "blue"}} onClick = {clickHandler} >{item.ticket_id}</td>
                    <td>{item.old_price}</td>
                    <td>{item.offer_price}</td>
                  </tr>
                )}
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModel}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default IsloggedinHOC(PopupModel);