import React, { useEffect, useState, useRef } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getProductList, addSearchItem } from '../../Redux/action/Product'
import IsLoadingHOC from '../../Components/IsLoadingHOC'
import IsloggedinHOC from '../../Components/IsLoggedinHOC'
import DummyModal from '../../Components/Modal'
import { getModuleData } from '../../Redux/action/App'
import FooterProduct from '../../Components/FooterProduct'
import Pagination from "react-js-pagination";
import './product.css'
import { toast } from 'react-toastify'
import AddManualProductModel from '../../Components/AddManuallyProduct'
import { debounce } from 'lodash';


const Product = (props) => {
    const { setLoading, isLoading } = props;
    const dispatch = useDispatch();
    const [productData, setProductData] = useState([]);
    const { footerProductData } = useSelector(state => state.app)
    const modal = useSelector(state => state.app)
    const [searchName, setSearchName] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setlimit] = useState(12)
    const [totalRecords, setTotalRecords] = useState(12000)
    const [manuallyProductModel, setManuallyProductModel] = useState(false)

    const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
    }

    const getProductListData = async (currentPage, limit, searchName) => {
        setLoading(true)
        await dispatch(getProductList(currentPage, limit, searchName))
            .then(
                response => {
                    setLoading(false);
                    setProductData(response.data)
                    setTotalRecords(response.count)
                },
                () => {
                    console.log(false);
                    setLoading(false);
                }
            )
            .catch(
                error => {
                    console.log(error)
                }
            )
    }

    useEffect(() => {
        getProductListData(currentPage, limit, searchName);
    }, [currentPage]);

    const handleModal = (item) => {
        let modelObj = {
            isModalOpen: true,
            product_details: item,
            show: true
        }
        dispatch(getModuleData(modelObj))
    }

    const delayedQuery = useRef(debounce(q => getProductListData(currentPage, limit, q), 500)).current;

    const onSearchHandler = (e) => {
        var current_value = e.target.value.toLowerCase();
        setCurrentPage(1)
        setSearchName(current_value)
        delayedQuery(current_value);
    }

    const capitalizeFirstLetter = (string) => {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    const manuallyProductHandler = async () => {
        setManuallyProductModel(!manuallyProductModel)
    }

    return (
        <>
            <div className="container ">
                <div className="row height d-flex justify-content-center align-items-center">
                    <div className="col-md-8">
                        <div className="search">
                            <i className="fa fa-search"></i>
                            <input type="text" className="form-control"
                                autoComplete="off"
                                value={searchName}
                                onChange={onSearchHandler}
                                placeholder="Search" />
                            <button className="btn btn-primary">Search</button>
                        </div>

                    </div>
                </div>
            </div>
            <Container fluid  >
                <br />
                <br />
                <Row>
                    {productData && productData.length > 0 &&
                        productData.map((item, index) => (
                            <Col className='collum' md={3} xs={12} key={index} >
                                <Card className='card' onClick={() => handleModal(item)}  >
                                    <Card.Img className="img-fluid c_img" variant="top" src={item.image === null ? "https://truth-events.com/wp-content/uploads/2019/09/dummy.jpg" : item?.images[0]?.src} />
                                    <Card.Body>
                                        <Card.Title className='card-title' >{capitalizeFirstLetter(item.title)}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                        )}
                    {productData.length == 0 && !isLoading &&
                        <div>
                            <h3 >Add products to begin submitting</h3>
                            <h4 >Can't Find Your Product?</h4>
                            <h5 onClick={manuallyProductHandler} >Add Product Manually</h5>
                        </div>
                    }
                </Row>
            </Container>

            {productData.length > 0 &&
                <div >
                    <Pagination className="pagination"
                        itemClass="page-item" // add it for bootstrap 4
                        linkClass="page-link" // add it for bootstrap 4
                        activePage={currentPage}
                        itemsCountPerPage={limit}
                        totalItemsCount={12000}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
            }

            {modal.current.isModalOpen &&
                <DummyModal />
            }
            {manuallyProductModel &&
                <AddManualProductModel manuallyProductHandler={manuallyProductHandler} />
            }
            {footerProductData && footerProductData.length > 0 && <FooterProduct />}
        </>
    )
}
export default IsLoadingHOC(IsloggedinHOC(Product))