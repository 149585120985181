export const closeModal = data => _dispatch => {
    return _dispatch( { type: "CLOSE_MODAL", payload: data } )
}

export const getModuleData = data => _dispatch => {
    return (
        _dispatch( { type: "MODAL_DATA_UPDATE", payload: data } )
    )
}

export const addProductAction = data => _dispatch => {
    return (
        _dispatch( { type: "ADD_PRODUCT_DATA", payload: data } )
    )
}

export const addFooterProductAction = data => _dispatch => {
    return (
        _dispatch( { type: "ADD_FOOTER_PRODUCT_DATA", payload: data } )
    )
}

export const addProductViewAction = data => _dispatch => {
    return (
        _dispatch({ type: "ADD_PRODUCT_VIEW_DATA", payload: data } )
    )
}

export const clearProductData = () => _dispatch => {
    return (
        _dispatch({ type: "CLEAR_PRODUCT_DATA" } )
    )
}

export const addInitialSubAction = data => _dispatch => {
    return (
        _dispatch( { type: "SET_INITIALSUB_DATA", payload: data } )
    )
}

export const changeQtyAction = data => _dispatch => {
    return (
        _dispatch( { type: "QTY_UPDATED_SUCCESS" } )
    )
}

export const addApprovedData = data => _dispatch => {
    return (
        _dispatch({ type: "ADD_APPROVED_DATA", payload: data } )
    )
}

export const addPendingData = data => _dispatch => {
    return (
        _dispatch({ type: "ADD_PENDING_DATA", payload: data } )
    )
}


