let initialState = {
    product_list: [],
    sub_product: []
}
export default function product(state = initialState, action) {
    switch (action.type) {
        case "PRODUCT_DATA":
            return { ...state, product_list: action.payload }
        default:
            return state
    }
}