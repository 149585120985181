import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import IsLoadingHOC from '../IsLoadingHOC';
import IsloggedinHOC from '../IsLoggedinHOC';
import { toast } from "react-toastify"
import { getUserDetails, updateUserProfile } from '../../Redux/action/Auth'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import countryList from 'react-select-country-list'
import Select from 'react-select'
import './profile.css'
import ChangePassword from './changePassword';

function UserProfile(props) {
    const { setLoading } = props
    const [country, setCountry] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState({});
    const { name, email } = userDetails ? userDetails : {}
   
    


    const getUserDetailsData = async () => {
        setLoading(true)
        await dispatch(getUserDetails())
            .then(
                response => {
                    setLoading(false);
                    setUserDetails(response.data)
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data.message);
                }
            )
            .catch(
                error => {
                    console.log(error)
                }
            )
    }

    useEffect(() => {
        getUserDetailsData();
    }, [])

    useEffect(() => {
        if (userDetails) {
            const fields = ['name', 'email', 'mobile', 'address1', 'address2', 'city', 'zip', 'country_code'];
            fields.forEach(field => formik.setFieldValue(field, userDetails[field], false));

            if (userDetails.country_code) {
                const country = userDetails.country_code.split('/')
                const dummyObj = {
                    value: country[0],
                    label: country[1]
                }
                setCountry(dummyObj)
            }
        }
    }, [userDetails]);

    const changeHandler = option => {
        setCountry(option)
        const value = option.value;
        const label = option.label
        const final_value = `${value}/${label}`
        return final_value
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            address1: '',
            address2: '',
            city: '',
            zip: '',
            country_code: '',
            role: "SELLER"
        },

        validationSchema: Yup.object({
            name: Yup.string()
                .required("Username is required")
                .min(5, 'Username must be at least 5 characters'),
            email: Yup.string()
                .required("Email is required")
                .email('Email is invalid')
                .matches('^\\S*$', '* This field cannot contain only blankspaces.'),
            mobile: Yup.string()
                .required("Mobile number is required"),
            address1: Yup.string()
                .required("Address Line 1 is required"),
            address2: Yup.string()
                .required("Address Line 2 is required"),
            city: Yup.string()
                .required("City is required"),
            zip: Yup.string()
                .required("Zip code is required"),
            country_code: Yup.string()
                .required("Country code is required"),
        }),
        //   validation schema ends
        onSubmit: async values => {
            console.log('on Submit hit ------', values);
            const fieldValues = values
            setLoading(true)
            await dispatch(updateUserProfile(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        toast.success(response.message)
                        getUserDetailsData()
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data.message);
                    }
                )
                .catch(
                    error => console.log(error)
                )
        },
    });

    return (
        <>
            <div className="container rounded bg-white mt-5 mb-5">
                <div className="row">
                    <div className="col-md-3 border-right">
                        <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img className="rounded-circle mt-5" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                            <span className="font-weight-bold">{name}</span>
                            <span className="text-black-50">{email}</span>
                            <span></span></div>
                    </div>
                    <div className="col-md-5 border-right">
                        <form onSubmit={formik.handleSubmit} >
                            <div className="p-3 py-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="text-right">Profile Settings</h4>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <label className="labels">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control" placeholder="Enter name" value={formik.values.name} />
                                        {formik.touched.name && formik.errors.name ? (<span className="text-danger " >{formik.errors.name}</span>) : null}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="labels">Email ID</label>
                                        <input
                                            type="text"
                                            name='email'
                                            disabled
                                            className="form-control"
                                            placeholder="Enter email id"
                                            onChange={formik.handleChange}
                                            value={formik.values.email} />
                                    </div>
                                    <div className="col-md-12">
                                        <label className="labels">Role</label>
                                        <input disabled type="text" name='role' onChange={formik.handleChange} className="form-control" placeholder="enter Role" value={formik.values.role} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label className="labels">Address Line 1</label>
                                        <input
                                            name='address1'
                                            value={formik.values.address1}
                                            onChange={formik.handleChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter address 1" />
                                        {formik.touched.address1 && formik.errors.address1 ? (<span className=" text-danger " >{formik.errors.address1}</span>) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">Address Line 2</label>
                                        <input
                                            type="text"
                                            name='address2'
                                            value={formik.values.address2}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                            placeholder="Enter address 2" />
                                        {formik.touched.address2 && formik.errors.address2 ? (<span className=" text-danger " >{formik.errors.address2}</span>) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label className="labels">City</label>
                                        <input
                                            type="text"
                                            name='city'
                                            value={formik.values.city}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                            placeholder="Enter City" />
                                        {formik.touched.city && formik.errors.city ? (<span className=" text-danger " >{formik.errors.city}</span>) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">Zip code</label>
                                        <input
                                            type="text"
                                            name='zip'
                                            value={formik.values.zip}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                            placeholder="Enter zip " />
                                        {formik.touched.zip && formik.errors.zip ? (<span className=" text-danger " >{formik.errors.zip}</span>) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <label className="labels">Mobile</label>
                                        <input
                                            type="number"
                                            name='mobile'
                                            value={formik.values.mobile}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                            placeholder="Enter Mobile" />
                                        {formik.touched.mobile && formik.errors.mobile ? (<span className=" text-danger " >{formik.errors.mobile}</span>) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">Country</label>
                                        <Select name="country_code" options={options} value={country} onChange={(option) => {
                                            formik.setFieldValue('country_code', changeHandler(option));
                                        }} />
                                    </div>
                                </div>
                                <div className="mt-5 text-center">
                                    <button className="btn btn-primary profile-button" type="submit">Save Profile</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <ChangePassword/>       
                </div>
            </div>
        </>
    )
}
export default IsLoadingHOC(IsloggedinHOC(UserProfile));